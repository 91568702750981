const assembleURL = (parts: Array<string | undefined>) =>
  [process.env.API_BASE_URL, ...parts.filter(Boolean)].join('/');

export const apiRoutes = {
  onboardingInvitations: (invitationId: string) =>
    assembleURL(['onboarding/v1/invitations', invitationId]),
  onboardingInvitationsPin: (invitationId: string) =>
    assembleURL(['onboarding/v1/invitations', invitationId, 'pin']),
  partyPartners: () => assembleURL(['party/v1/partners']),
  partyPayee: (partyId: string) => assembleURL(['party/v1/payee', partyId]),
  partyPayees: () => assembleURL(['party/v1/payees']),
  partyPayeeBankAccount: (partyId: string) =>
    assembleURL(['party/v1/payee', partyId, 'bank-account']),
  partyPayeePartner: (partyId: string) =>
    assembleURL(['party/v1/payee', partyId, 'partner']),
  projectItemPayments: () => assembleURL(['project/v1/item-payments']),
  projectProjects: () => assembleURL(['project/v1/projects']),
  treasuryBankAccount: (bankAccountId: string) =>
    assembleURL(['treasury/v1/bank-accounts', bankAccountId]),
  treasuryBankAccounts: () => assembleURL(['treasury/v1/bank-accounts']),
  treasuryBankAccountPayments: (bankAccountId: string) =>
    assembleURL(['treasury/v1/bank-accounts', bankAccountId, 'payments']),
  validationCountries: () => assembleURL(['validation/v1/countries']),
};
