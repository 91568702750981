import { useMemo } from 'react';

import { useSteamHammer } from '../components/steamhammer-provider/steamhammer-provider';
import { Method, Options } from '../types/fetch';
import {
  requestWithBody,
  resolveRequestResponseOrThrowError,
} from '../utilities/fetch';
import { queryParams } from '../utilities/query-params';

export const useFetchAndKey = <Body>(
  method: Method,
  urlPart: string,
  { params = {} }: Partial<Options>,
) => {
  const { getHeaders } = useSteamHammer();

  const url = urlPart + queryParams(params);

  return {
    fetch: useMemo(() => {
      switch (method) {
        case 'GET':
          return async () => {
            const headers = await getHeaders();

            return resolveRequestResponseOrThrowError(
              await fetch(url, { method: 'GET', headers }),
            );
          };

        case 'POST':
          return requestWithBody<Body>('POST', url, getHeaders);

        case 'PATCH':
          return requestWithBody<Body>('PATCH', url, getHeaders);
      }
    }, [method, getHeaders, url]),
    key: [urlPart, params],
  };
};
