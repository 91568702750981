import { FormattedMessage } from 'react-intl';

import { Layout, Link } from '@shieldpay/bumblebee';

export const SupportLinks = () => (
  <Layout spacing="base">
    <Link href="https://www.shieldpay.com/about" target="_blank" size="small">
      <FormattedMessage id="shieldpay.onboarding.aboutShieldpay.button" />
    </Link>
    <Link
      href="https://support.shieldpay.com/hc/en-us/requests/new"
      target="_blank"
      size="small"
    >
      <FormattedMessage id="shieldpay.needHelp.button" />
    </Link>
  </Layout>
);
