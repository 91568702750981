import { Params } from '../types/fetch';

export const queryParams = (params: Params): string => {
  const paramsToUse = Object.entries(params)
    .map(([param, value]) => ({ param, value }))
    .filter(
      (check): check is { param: string; value: string | number } =>
        check.value !== undefined && check.value !== null,
    );

  return paramsToUse.length > 0
    ? `?${paramsToUse.map(({ param, value }) => `${param}=${value}`).join('&')}`
    : '';
};
