import { css } from '@emotion/react';

import { svgIconChild, themeifyStyle } from '../../themes/utilities';

export const icon = css({
  display: 'inline-block',
  verticalAlign: 'middle',
});

export const iconSize = {
  xs: themeifyStyle(({ iconSizes }) => ({
    height: iconSizes.xs,
    width: iconSizes.xs,
  })),
  s: themeifyStyle(({ iconSizes }) => ({
    height: iconSizes.s,
    width: iconSizes.s,
  })),
  m: themeifyStyle(({ iconSizes }) => ({
    height: iconSizes.m,
    width: iconSizes.m,
  })),
  l: themeifyStyle(({ iconSizes }) => ({
    height: iconSizes.l,
    width: iconSizes.l,
  })),
  xl: themeifyStyle(({ iconSizes }) => ({
    height: iconSizes.xl,
    width: iconSizes.xl,
  })),
};

export const svg = css({
  height: '100%',
  width: 'auto',
  ...svgIconChild,
});
