/**
 * The returned validator function returns true if the value length is greater than
 * or equal to the given length, otherwise it returns false.
 */
export const createMinLength =
  (length: number) => (value: string | undefined) =>
    value === undefined ? false : value.length >= length;

/**
 * The returned validator function returns true if the value length is less than or
 * equal to the given length, otherwise it returns false.
 */
export const createMaxLength =
  (length: number) => (value: string | undefined) =>
    value === undefined ? true : value.length <= length;

/**
 * The returned validator function returns true if the value length is
 * equal to the given length, otherwise it returns false.
 */
export const createIsLength =
  (length: number) =>
  (value: string | undefined): boolean =>
    value?.length === length;

/**
 * Returns true if the value contains an upper case character, otherwise false.
 * (ISO basic Latin alphabet)
 */
export const containsUpperCase = (value: string | undefined): boolean =>
  !!value && /[A-Z]/.test(value);

/**
 * Returns true if the value contains a lower case character, otherwise false.
 * (ISO basic Latin alphabet)
 */
export const containsLowerCase = (value: string | undefined): boolean =>
  !!value && /[a-z]/.test(value);

/**
 * Returns true if the value contains a number, otherwise false.
 */
export const containsNumber = (value: string | undefined): boolean =>
  !!value && /\d/.test(value);

/**
 * Returns true if the value only contains digits, otherwise false.
 */
export const digitsOnly = (value: string | undefined): boolean =>
  !!value && /^\d+$/.test(value);

/**
 * Returns true if the value only contains alphanumeric characters, otherwise false.
 */
export const upperCaseAlphaNumericOnly = (value: string | undefined): boolean =>
  !!value && /^[A-Z0-9]+$/.test(value);
/**
 * Returns true if the value contains a value, otherwise false.
 */
export const required = (value: string | undefined): boolean => !!value?.trim();

/**
 * Returns true if the value contains one of the following special characters:
 *
 * = + - ^ $ * . [ ] { } ( ) ? " ! @ # % & / \ , > < ' : ; | _ ~ `
 *
 * otherwise false.
 *
 * https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
 *
 */
const EMAIL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /[\=\+\-\^\$\*\.\[\]\{\}\(\)\?\"\!\@\#\%\&\/\\\,\>\<\'\:\;\|\_\~\`]/;

export const containsSpecialCharacter = (value: string | undefined): boolean =>
  !!value && EMAIL_REGEX.test(value);
