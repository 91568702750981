import { ElementType, ForwardedRef, forwardRef, ReactNode, Ref } from 'react';

import {
  LinkOrButton,
  useClickableElementStyles,
  UseClickableElementStylesOptions,
} from '../../hooks/use-clickable-element-styles/use-clickable-element-styles';
import { Box } from '../box/box';
import { useBumblebeeContext } from '../bumblebee-provider/bumblebee-provider';
import { Text, TextProps } from '../text/text';

export type ToOrHref =
  | {
      /**
       * the to prop is used when we want the link to work as a router link
       */
      to: string;
      href?: never;
      target?: never;
    }
  | {
      /**
       * the href prop is used when we want the link to work outside our app
       */
      href: string;
      to?: never;
      target?: '_blank';
    };

export type LinkProps = Omit<TextProps, 'variant' | 'paragraph' | 'color'> &
  Omit<UseClickableElementStylesOptions, 'size' | 'variant'> &
  Partial<LinkOrButton> &
  ToOrHref & {
    disabled?: boolean;
    children: ReactNode;
    LeftIcon?: ElementType;
    RightIcon?: ElementType;
    'data-testid'?: string;
    forwardRef?: Ref<unknown>;
  };

export const TEST_IDS = {
  LINK: 'link-component',
  LEFT_ICON: 'left-icon',
  RIGHT_ICON: 'right-icon',
};

export const LinkWithoutForwardRef = (
  {
    children,
    Component = 'a',
    disabled = false,
    size = 'inherit',
    LeftIcon,
    RightIcon,
    variant = LeftIcon || RightIcon ? 'text-with-icon' : 'text-underline',
    to,
    ...props
  }: LinkProps,
  ref: ForwardedRef<unknown>,
) => {
  const { NavLink } = useBumblebeeContext();

  const { styles, iconSize, textVariant, padding, rounded, linkResetStyles } =
    useClickableElementStyles({
      ...({ size, variant } as LinkOrButton),
      disabled,
    });

  return (
    <Box
      disabled={disabled}
      Component={disabled ? 'span' : to ? NavLink : Component}
      variant="initial" //styles set by useClickableElementStyles
      rounded={rounded}
      css={[linkResetStyles, styles]}
      padding={{ x: padding.x }}
      to={disabled ? undefined : to}
      data-testid={TEST_IDS.LINK}
      ref={ref}
      {...props}
    >
      {LeftIcon && (
        <LeftIcon size={iconSize} data-testid={TEST_IDS.LEFT_ICON} />
      )}
      <Text
        color="currentColor"
        variant={textVariant}
        padding={{ y: padding.y }}
      >
        {children}
      </Text>
      {RightIcon && (
        <RightIcon size={iconSize} data-testid={TEST_IDS.RIGHT_ICON} />
      )}
    </Box>
  );
};

// Manually reset the displayName in the component tree.
LinkWithoutForwardRef.displayName = 'Link';

export const Link = forwardRef(LinkWithoutForwardRef);
