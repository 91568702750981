import { Children, cloneElement, ReactElement, ReactNode } from 'react';

import { Box } from '../box/box';
import { Text, TextProps } from '../text/text';

import * as styles from './text-list.styles';

export interface TextListItemProps
  extends Pick<TextProps, 'variant' | 'color'> {
  children: ReactNode;
}

export interface TextListProps extends TextListItemProps {
  ordered?: boolean;
}

export const TextListItem = ({
  children,
  variant,
  color,
}: TextListItemProps) => (
  <Text color={color} Component="li" variant={variant} css={styles.item}>
    {children}
  </Text>
);

export const TextList = ({
  children,
  ordered = false,
  variant = 'body150',
  color,
  ...boxProps
}: TextListProps) => {
  const ListComponent = ordered ? 'ol' : 'ul';

  return (
    <Box
      Component={ListComponent}
      variant="initial"
      css={[styles.base, styles.type[ListComponent]]}
      {...boxProps}
    >
      {Children.map(children, (child) =>
        cloneElement(child as ReactElement<TextListItemProps>, {
          variant,
          color,
        }),
      )}
    </Box>
  );
};

TextList.Item = TextListItem;
