import { transformValues } from '@shieldpay/utility-functions-ui';

import {
  RemoveInputAction,
  SetValidationAction,
  SetValueAction,
} from './types';

export const setValue = <Name, Value>(
  payload: SetValueAction<Name, Value>['payload'],
): SetValueAction<Name, Value> => ({
  payload,
  type: 'SET_VALUE',
});

export const setValidation = <Name>({
  name,
  validation,
  validationMessage,
}: SetValidationAction<Name>['payload']): SetValidationAction<Name> => ({
  payload: {
    name,
    validation: !validationMessage
      ? validation
      : // If field-level validationMessage exists, apply to all validation
        transformValues(validation, ({ valid }) => ({
          valid,
          message: validationMessage,
        })),
  },
  type: 'SET_VALIDATION',
});

export const removeField = <Name>(name: Name): RemoveInputAction<Name> => ({
  payload: { name },
  type: 'REMOVE_FIELD',
});
