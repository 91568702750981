import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import { FetchError, Options, Pagination } from '../types/fetch';

import { useFetchAndKey } from './use-fetch-and-key';

const INITIAL_PAGINATION: Pagination = {
  limit: 20,
  pageSize: 0,
  offset: 0,
  total: 0,
};

/** filter pagination parameters rejected by api */
const paginationToParams = ({ offset, limit }: Pagination) => ({
  limit,
  offset,
});

/**
 * Takes two optional generics:
 *
 * Payload: The expected data payload
 * Error: If you want to make the error more specific than a generic error
 */
export const useGet = <Payload = unknown, Error = FetchError>(
  urlPart: string,
  options: Partial<Options> = {},
) => {
  const [requestedPagination, setRequestedPagination] =
    useState(INITIAL_PAGINATION);

  const { fetch, key } = useFetchAndKey('GET', urlPart, {
    ...options,
    params: {
      ...options.params,
      ...paginationToParams(requestedPagination),
    },
  });

  /** Add in pagination to the shape of the data returned on this endpoint */
  const query = useQuery<Payload & { pagination?: Pagination }, Error>(
    key,
    fetch,
    {
      keepPreviousData: true,
    },
  );

  const pagination =
    !query.data || !query.data.pagination || query.isLoading
      ? requestedPagination
      : query.data.pagination;

  return {
    ...query,
    pagination: {
      ...pagination,
      onOffsetChange: (offset: number) => {
        setRequestedPagination({
          ...pagination,
          offset,
          // speculate the new pageSize by calculating the amount of entries left
          // with the new offset and previous total, and returning this if smaller than limit
          pageSize: Math.min(pagination.total - offset, pagination.limit),
        });
      },
    },
  };
};

/**
 * Takes three optional generics:
 *
 * Body: The values the endpoint accepts
 * Payload: The expected data payload
 * Error: If you want to make the error more specific than a generic error
 */
export const usePost = <Body = void, Payload = unknown, Error = FetchError>(
  urlPart: string,
  options: Partial<Options> = {},
) => {
  const { fetch } = useFetchAndKey<Body>('POST', urlPart, options);

  return useMutation<Payload, Error, Body>(fetch);
};

/**
 * Takes three optional generics:
 *
 * Body: The values the endpoint accepts
 * Payload: The expected data payload
 * Error: If you want to make the error more specific than a generic error
 */
export const usePatch = <Body = void, Payload = unknown, Error = FetchError>(
  urlPart: string,
  options: Partial<Options> = {},
) => {
  const { fetch } = useFetchAndKey<Body>('PATCH', urlPart, options);

  return useMutation<Payload, Error, Body>(fetch);
};
