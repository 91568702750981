import { FunctionValidators, ValidationState, Validator } from './types';

export const validationStateEquals = (
  v1?: ValidationState,
  v2?: ValidationState,
): boolean => {
  const stringify = (obj: ValidationState) =>
    Object.values(obj)
      .map((val) => JSON.stringify(val))
      .join('');

  return !v1 || !v2 ? false : stringify(v1) === stringify(v2);
};

export const validatorsEquals = <Value>(
  v1: Validator<Value>[],
  v2: Validator<Value>[],
) => JSON.stringify(v1) === JSON.stringify(v2);

/**
 * Returns a filtered array of validators where the `test` prop is a boolean value
 */
export const getBooleanValidators = <Value>(
  validators: Validator<Value>[],
): Validator<Value>[] =>
  validators.filter(({ test }) => typeof test === 'boolean');

/**
 * Merges an array of validators into another.
 */
export const mergeValidators = <Value>(
  merge: Array<Validator<Value>>,
  into: Array<Validator<Value>>,
): Array<Validator<Value>> => [
  ...(merge.length
    ? into.filter((validator) =>
        merge.some(({ name }) => name !== validator.name),
      )
    : into),
  ...merge,
];

/**
 * Returns the result of a validator function or a validator's `test` property
 * if said `test` prop is a boolean value.
 */
const runValidator = async <Value>(
  validator: Validator<Value>,
  value: Value,
): Promise<{ name: string; valid: boolean; message?: string }> => {
  const runTests = (tests: FunctionValidators<Value>) =>
    [tests].flat().map((test) => test(value));

  if (validator.testUndefinedValue) {
    // return early, these validators do not need to be async
    return {
      name: validator.name,
      valid: runTests(validator.test).every((valid) => valid),
      message: validator.message,
    };
  }

  const testValue = async (test: FunctionValidators<Value> | boolean) => {
    if (typeof test === 'boolean') return test;

    return await Promise.all(runTests(test)).then((results) =>
      results.every((valid) => valid),
    );
  };

  return {
    name: validator.name,
    valid: await testValue(validator.test),
    message: validator.message,
  };
};

export const runValidators = async <Value>(
  validators: Array<Validator<Value>>,
  value: Value,
  batchId: string,
): Promise<{ validationState: ValidationState; batchId: string }> => {
  const resolved = await Promise.all(
    validators.map(async (validator) => await runValidator(validator, value)),
  );

  return {
    validationState: resolved.reduce<ValidationState>(
      (state, validatorResult) => ({
        ...state,
        [validatorResult.name]: {
          valid: validatorResult.valid,
          message: validatorResult.message,
        },
      }),
      {},
    ),
    batchId,
  };
};
