import { Action, InputState } from './types';

export const createInitialState = () => ({
  touched: {},
  valid: false,
  validFields: {},
  validating: {},
  validation: {},
  values: {},
});

export type BlurrReducer<Inputs, State = InputState<Inputs>> = (
  state: State,
  action: Action<Inputs>,
) => State;

export const createReducer =
  <Inputs>() =>
  (state: InputState<Inputs>, action: Action<Inputs>) => {
    switch (action.type) {
      case 'SET_VALUE': {
        const {
          payload: { fromInitial, name, value, validating },
        } = action;

        const newState = {
          ...state,
          touched: {
            ...state.touched,
            [name]: !fromInitial,
          },
          values: {
            ...state.values,
            [name]: value,
          },
          validating: {
            ...state.validating,
            [name]: validating,
          },
        };

        return newState;
      }

      case 'SET_VALIDATION': {
        const {
          payload: { name, validation },
        } = action;

        const newState = {
          ...state,
          validating: {
            ...state.validating,
            [name]: false,
          },
          validation: {
            ...state.validation,
            [name]: {
              ...state.validation[name],
              ...validation,
            },
          },
          validFields: {
            ...state.validFields,
            [name]: Object.values(validation).every(({ valid }) => valid),
          },
        };

        newState.valid = Object.values(newState.validFields).every(
          (valid) => valid,
        );

        return newState;
      }

      case 'REMOVE_FIELD': {
        const {
          payload: { name },
        } = action;

        const newState = {
          ...state,
        };

        delete newState.touched[name];
        delete newState.validation[name];
        delete newState.values[name];
        delete newState.validating[name];
        delete newState.validFields[name];

        newState.valid = Object.values(newState.validFields).every(
          (valid) => valid,
        );

        return newState;
      }

      default:
        return state;
    }
  };
