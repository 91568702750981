import { useReducer, useRef } from 'react';
import debounce from 'lodash.debounce';

import { removeField, setValidation, setValue } from './actions';
import { BlurrReducer, createInitialState, createReducer } from './reducer';
import {
  Blurr,
  RequiredValidator,
  ValidationProps,
  ValidationState,
} from './types';
import {
  getBooleanValidators,
  mergeValidators,
  runValidators,
  validationStateEquals,
  validatorsEquals,
} from './validation';

interface Cache {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getOrSet: (key: string, value: any) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set: (key: string, value: any) => Map<any, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  has: (key: string) => boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get: (key: string) => any;
}

function useCache(): Cache {
  const cache = useRef(new Map());
  const has: Cache['has'] = (key) => cache.current.has(key);
  const get: Cache['get'] = (key) => cache.current.get(key);
  const set: Cache['set'] = (key, value) => cache.current.set(key, value);
  const getOrSet: Cache['getOrSet'] = (key, value) =>
    has(key) ? get(key) : set(key, value) && get(key);

  return { getOrSet, set, has, get };
}

export function useBlurr<Inputs>(): Blurr<Inputs> {
  const [inputState, dispatch] = useReducer<BlurrReducer<Inputs>>(
    createReducer(),
    createInitialState(),
  );

  const fieldCache = useCache();

  const inputProps: Blurr<Inputs>['inputProps'] = ({
    initialValue,
    name,
    validation,
    validators = [],
    validationMessage,
  }) => {
    const nameString = name.toString();
    const cacheKeys = {
      validationProp: `${nameString}-validationProp`,
      validationState: `${nameString}-validationState`,
      booleanValidators: `${nameString}-booleanValidators`,
      finalValidationBatchId: `${nameString}-finalValidationBatchId`,
      onChange: `${nameString}-onChange`,
      changes: `${nameString}-changes`,
      onBlur: `${nameString}-onBlur`,
      onRemove: `${nameString}-onRemove`,
    };

    const hasValidators = !!validators.length;

    // We're tracking input change count to conditionally perform debounced
    // validation behaviour
    fieldCache.getOrSet(cacheKeys.changes, 0);

    const handleRunValidators = async <
      ValueOrUndefined = Inputs[typeof name] | undefined,
    >(
      value: ValueOrUndefined,
    ) => {
      if (!hasValidators) {
        dispatch(
          setValidation({
            name,
            validation: {},
          }),
        );
        //return early if no validation tests
        return;
      }

      // crypto.randomUUID() does not work on remix server, but this should
      // reliably work everywhere.
      const newBatchId = nameString + Date.now();

      // Find any validators that test for undefined values.
      // We do this so we can run them before other validators
      // and fail the field early before running more complex
      // pattern validators.
      const testUndefinedValidator = validators.find(
        ({ testUndefinedValue }) => testUndefinedValue,
      ) as RequiredValidator<ValueOrUndefined>;

      const failedEarly = testUndefinedValidator
        ? !testUndefinedValidator.test(value)
        : false;

      fieldCache.set(cacheKeys.finalValidationBatchId, newBatchId);

      const { validationState, batchId: completedBatchId } = failedEarly
        ? //  If the field has failed the required validator then do not run any other tests
          {
            validationState: {
              [testUndefinedValidator.name]: {
                valid: false,
                message: testUndefinedValidator?.message,
              },
            },
            batchId: newBatchId,
          }
        : await runValidators(
            // Merge cached (fresh) boolean validators with original in case
            // state updated externally.
            mergeValidators(
              fieldCache.get(cacheKeys.booleanValidators) || [],
              validators,
            ),
            value as unknown as Inputs[typeof name],
            newBatchId,
          );

      // Only update state after last validation batch
      if (
        completedBatchId === fieldCache.get(cacheKeys.finalValidationBatchId)
      ) {
        dispatch(
          setValidation({
            name,
            validation: validationState,
            validationMessage,
          }),
        );
      }
    };

    const handleRunValidatorsDebounced = debounce(
      handleRunValidators,
      validation?.debounce || 0,
    );

    // If the field is new to the state, dispatch an initial setValue
    // to kick things off. This is how we dynamically add fields when
    // we call the props creator.
    if (!(name in inputState.values)) {
      dispatch(
        setValue({
          name,
          fromInitial: true,
          value: initialValue ?? undefined,
          validating: hasValidators,
        }),
      );

      handleRunValidators(initialValue);
    }

    const validationState = inputState.validation[name] || {};
    const cachedValidation = fieldCache.get(cacheKeys.validationState);

    fieldCache.getOrSet(
      cacheKeys.booleanValidators,
      getBooleanValidators(validators),
    );

    // If cached boolean validators don't equal current boolean validators,
    // we need to update validation state.
    if (
      !validatorsEquals(
        getBooleanValidators(validators),
        fieldCache.get(cacheKeys.booleanValidators),
      )
    ) {
      fieldCache.set(
        cacheKeys.booleanValidators,
        getBooleanValidators(validators),
      );

      handleRunValidators(inputState.values[name]);
    }

    // Cache transformed validation state to prevent unnecessary renders
    if (
      !cachedValidation ||
      !validationStateEquals(cachedValidation, validationState)
    ) {
      fieldCache.set(cacheKeys.validationState, validationState);
      fieldCache.set(
        cacheKeys.validationProp,
        Object.values(
          validationState as ValidationState,
        ).reduce<ValidationProps>(
          (acc, curr) => ({
            messages:
              !curr.valid && validationMessage
                ? [validationMessage]
                : !curr.valid && curr.message
                ? [...acc.messages, curr.message]
                : [...acc.messages],
            valid: acc.valid && curr.valid,
          }),
          { valid: true, messages: [] },
        ),
      );
    }

    const handleChange = fieldCache.getOrSet(
      cacheKeys.onChange,
      async (value: Inputs[typeof name]) => {
        dispatch(
          setValue({
            name,
            value,
            validating: hasValidators,
          }),
        );

        fieldCache.set(
          cacheKeys.changes,
          fieldCache.get(cacheKeys.changes) + 1,
        );

        if (!hasValidators) return;

        const dispatchValidators =
          !!validation?.debounce && fieldCache.get(cacheKeys.changes) > 1
            ? handleRunValidatorsDebounced
            : handleRunValidators;

        dispatchValidators(value);
      },
    );

    return {
      name,
      id: name,
      // on initialRender inputState.values[name] will not have a value, but this can throw React warnings.
      // e.g. it can change an uncontrolled component into a controlled one and downshiftJs does not like this.
      value: inputState.values[name] ?? initialValue,
      touched: !!inputState.touched[name],
      validation: fieldCache.get(cacheKeys.validationProp),
      valid: !!inputState.validFields[name],
      validating: !!inputState.validating[name],
      required:
        hasValidators && !!validators.find(({ name }) => name === 'required'),
      onChange: handleChange,
      onBlur: fieldCache.getOrSet(
        cacheKeys.onBlur,
        (value: Inputs[typeof name]) => {
          if (validation?.onBlur) {
            handleChange(value);
          }
        },
      ),
      onRemove: fieldCache.getOrSet(cacheKeys.onRemove, () => {
        dispatch(removeField(name));
      }),
    };
  };

  return { inputState, inputProps };
}
