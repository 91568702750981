import { SyntheticEvent, useEffect, useState } from 'react';

import { InputState } from '@shieldpay/blurr';

export interface UseValidationVisibilityParams<Fields> {
  inputState: InputState<Fields>;
  fields: Array<keyof Fields>;
  onSubmit?: (data: Fields) => void;
  hasExternalError?: boolean;
  resetExternalError?: () => void;
  /**
   * limit the fields used to calculate the externalValidationErrorTest
   * boolean validator used for validating fields.
   */
  externalValidationFields?: Array<keyof Fields>;
}

export type ValidationVisibilityProps<Fields> = Record<
  keyof Fields,
  { showValidation: boolean; onBlur: () => void }
>;

const isFalsyFieldValue = (value: unknown) =>
  value === undefined || (typeof value === 'string' && value.length === 0);

/*
 * A hook for displaying validation on fields, using the following pattern:
 *
 * 1. open fresh form
 * 2. focus on a field
 * 3. exit field in an invalid state (or click submit)
 * 4. validation shows for that field (or if submit pressed all fields)
 * 5. focus back on a field
 * 6. make a change
 * 7. validation disappears for that field
 **/
export const useValidationVisibility = <Fields>({
  inputState: { values, valid, validFields },
  fields,
  onSubmit,
  hasExternalError,
  resetExternalError,
  externalValidationFields,
}: UseValidationVisibilityParams<Fields>) => {
  /**
   * A record of the values at point we want to show validation if invalid.
   * This is used to assert if the field value has changed since that point.
   */
  const [validatedValues, setValidatedValues] = useState<Partial<Fields>>({});

  /**
   * These are fields which we do not want to show validation on yet even if
   * the validators fail.
   * These are all true by default, but switch to false when either:
   * a: the field is unfocused with a value in an invalid state
   * b: submit has been called.
   * NOTE: The value for a field can only switch from true to false.
   */
  const noFeedbackFields = Object.fromEntries(
    fields.map((name) => [
      [name],
      !Object.prototype.hasOwnProperty.call(validatedValues, name),
    ]),
  );

  /**
   * Fields which are no longer noFeedbackFields and have not changed
   * since a field has been unfocussed or submit has been pressed
   */
  const showValidationForFields = Object.fromEntries(
    fields.map((name) => [
      [name],
      !noFeedbackFields[name] && values[name] === validatedValues[name],
    ]),
  );

  /**
   * Track if any of the fields with external validation have
   * changed since a test failure.
   *
   * TODO: allow this to be defined on a field by field basis
   * when we have a need to do this.
   */
  const noExternalFieldsChangedSinceValidation = (
    externalValidationFields || fields
  ).every((name) => showValidationForFields[name]);

  /**
   * Value designed to be passed to Blurr validators as a boolean `test`
   */
  const externalValidationErrorTest =
    hasExternalError && noExternalFieldsChangedSinceValidation
      ? false // at least one field has failed validation
      : true; // all fields pass

  useEffect(() => {
    // reset the backend error state if any fields have changed since failing
    // and at least one of the field values has changed.
    if (hasExternalError && !noExternalFieldsChangedSinceValidation) {
      resetExternalError?.();
    }
  }, [
    hasExternalError,
    resetExternalError,
    noExternalFieldsChangedSinceValidation,
  ]);

  const validationVisibilityProps: Record<
    keyof Fields,
    { showValidation: boolean; onBlur: () => void }
  > = Object.fromEntries(
    fields.map((name) => [
      [name],
      {
        /*
         * Only show validation when
         * a: field has been blurred OR form submission attempted
         * AND
         * b: field value has not changed since the last check
         **/
        showValidation: !validFields[name] && showValidationForFields[name],
        /*
         * Store field value when focus has been lost, the field is 'dirty' (not just 'touched')
         * AND importantly do not store if the value is undefined or an empty sting.
         **/
        onBlur: () => {
          // Do not mark a field as validated when field has never been blurred
          // in an invalid state which is not an empty value.
          if (noFeedbackFields[name] && isFalsyFieldValue(values[name])) return;

          setValidatedValues((fields) => ({
            ...fields,
            [name]: values[name],
          }));
        },
      },
    ]),
  );

  return {
    handleSubmit: (e?: SyntheticEvent) => {
      e?.preventDefault();

      /*
       * Store all field values when submitting
       **/
      setValidatedValues(values);

      if (valid) {
        onSubmit?.(values as Fields);
      }

      return valid;
    },
    validationVisibilityProps,
    externalValidationErrorTest,
  };
};
