import { Box, BoxProps } from '../box/box';

type FormProps = Pick<
  BoxProps,
  | 'children'
  | 'spacing'
  | 'padding'
  | 'title'
  | 'onSubmit'
  | 'Component'
  | 'method'
  | 'autoComplete'
>;

export const Form = ({ Component = 'form', ...props }: FormProps) => (
  <Box Component={Component} {...props} />
);
