import {
  createThemedStyleObjects,
  focusRingStyle,
  themeifyStyle,
} from '../../themes/utilities';

export const container = themeifyStyle((theme) => ({
  transition: `box-shadow ${theme.transitions.standard}, ${
    focusRingStyle(theme).transition
  }`,
  ':hover': {
    border: `${theme.spacing.baseNeg8}px solid ${theme.palette.neutral500}`,
  },
  ':focus-within': {
    ...focusRingStyle(theme).style,
  },
}));

export const [input] = createThemedStyleObjects((theme) => ({
  base: {
    border: `${theme.spacing.baseNeg8}px solid transparent`,
    color: theme.palette.neutral500,
    flex: '1 0',
    fontFamily: theme.typography.fontFamily,
    ':focus': {
      outline: 'none',
    },
  },
  disabled: {
    background: theme.palette.neutral000,
    color: theme.palette.neutral300,
  },
  padding: {
    padding: `${theme.spacing.base}px ${theme.spacing.base}px`,
  },
  withAdjacentSibling: {
    paddingRight: '0',
  },
}));

export const [states] = createThemedStyleObjects((theme) => ({
  disabled: {
    ':hover': {
      borderColor: theme.palette.neutral250,
    },
  },
  error: {
    borderColor: theme.palette.alert500,
    ':focus-within': {
      borderColor: theme.palette.primary500,
    },
  },
}));
