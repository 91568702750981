import { Children, cloneElement, ReactElement, ReactNode } from 'react';

import { Color, Palette } from '../box/use-box.styles';
import { colorPropStyles } from '../box/use-box.styles';
import * as styles from './icon.styles';

export type IconType = 'warning' | 'error' | 'info';

export type IconSize = keyof typeof styles.iconSize;

export type IconProps = {
  className?: string;
  size?: IconSize;
  children?: ReactNode;
  'data-testid'?: string;
} & ( // type and color cannot be used together
  | {
      type: IconType;
      color?: never;
    }
  | {
      type?: never;
      color?: Color;
    }
);

const iconColors: Record<IconType, Palette> = {
  warning: 'alert500',
  error: 'alert600',
  info: 'info600',
};

export const Icon = ({
  className,
  size = 'm',
  children,
  type,
  color = 'currentColor',
  'data-testid': testId,
}: IconProps) => {
  // Pass down props to rendered SVG
  const svgChildren = Children.map(children, (child, index) =>
    cloneElement(child as ReactElement, {
      css: [styles.svg],
      preserveAspectRatio: 'none',
      index,
    }),
  );

  const iconColor = (type && iconColors[type]) || color;

  return (
    <span
      aria-hidden="true"
      className={className}
      css={[
        styles.icon,
        styles.iconSize[size],
        iconColor && colorPropStyles(iconColor, 'color'),
      ]}
      data-testid={testId}
    >
      {svgChildren}
    </span>
  );
};
