import { ReactNode } from 'react';

import { Box } from '../box/box';
import { AlertIcon, InfoIcon } from '../icons';
import { Text } from '../text/text';

import { Palette } from '../box/use-box.styles';

type Variant = keyof typeof variants;

interface InfoblockProps {
  children: ReactNode;
  variant?: Variant;
}

export const TEST_IDS = {
  INFO_BLOCK: 'infoblock',
  ICON_INFO: 'infoblockIconInfo',
  ICON_ERROR: 'infoblockIconError',
};

const variants = {
  info: {
    Component: InfoIcon,
    textColor: 'info600',
    background: 'info200',
    testId: TEST_IDS.ICON_INFO,
  },
  error: {
    Component: AlertIcon,
    textColor: 'alert600',
    background: 'alert200',
    testId: TEST_IDS.ICON_ERROR,
  },
} satisfies Record<
  string,
  Record<string, unknown> & Record<'textColor' | 'background', Palette>
>;

export const Infoblock = ({ children, variant = 'info' }: InfoblockProps) => {
  const { Component, textColor, testId, background } = variants[variant];
  return (
    <Box
      background={background}
      stack="row"
      alignContent={{ x: 'left' }}
      padding={{ x: 'base', y: 'baseNeg2' }}
      spacing="baseNeg3"
      data-testid={TEST_IDS.INFO_BLOCK}
      rounded
    >
      <Component data-testid={testId} type={variant} size="s" />

      {/* TODO - investigate how to replace component prop and create an API that accepts an array of error messages. */}
      <Text variant="caption150" color={textColor} Component="div">
        {children}
      </Text>
    </Box>
  );
};
