import { ElementType } from 'react';
import { Theme } from '@emotion/react';

import { Text, TextProps } from '../text/text';

import * as styles from './heading.styles';

// We could just list some string literals here, but the thinking
// is that picking from an existing type will show errors quicker
type Variant = keyof Pick<
  Theme['typography']['variant'],
  | 'headline1'
  | 'headline2'
  | 'caption'
  | 'title'
  | 'section'
  | 'bodyMedium150'
  | 'captionMedium150'
>;

const componentMap: { [level: string]: ElementType } = {
  '1': 'h1',
  '2': 'h2',
  '3': 'h3',
  '4': 'h4',
  '5': 'h5',
};

export interface HeadingProps extends Pick<TextProps, 'children' | 'align'> {
  level?: keyof typeof componentMap;
  variant?: Variant;
  id?: string;
}

export const Heading = ({
  level = '1',
  variant = 'headline1',
  children,
  id,
  align,
}: HeadingProps) => {
  return (
    <Text
      Component={componentMap[level]}
      css={[styles.heading]}
      variant={variant}
      id={id}
      align={align}
    >
      {children}
    </Text>
  );
};
