import { MethodWithBody } from '../types/fetch';

export const resolveRequestResponseOrThrowError = async (res: Response) => {
  const body = await res.json();

  if (!res.ok) {
    /* 
        Throw an object with relevant response data.
        It's useful to use the Error constructor function
        alongside the other data as it provides a stack trace.
      */
    throw {
      error: new Error(JSON.stringify(body)),
      statusCode: res.status,
      body,
    };
  }

  return body;
};

/**
 * For the creation of handlers supplying a body, e.g. POST or PATCH
 */
export const requestWithBody =
  <Body>(
    method: MethodWithBody,
    url: string,
    getHeaders: () => RequestInit['headers'] | Promise<RequestInit['headers']>,
  ) =>
  async (body: Body) => {
    const headers = await getHeaders();

    return resolveRequestResponseOrThrowError(
      await fetch(url, {
        method,
        body: JSON.stringify(body),
        headers: {
          ...headers,
          'content-type': 'application/json',
        },
      }),
    );
  };
